@import '../../../../constants/constants.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: .5rem 1rem .5rem 0;
    color: $colorHeader;

    @media (max-width: 750px) {
        display: none;
        font-size: .5rem;
    }

}


.title {
    margin: 0;
    font-size: $fontSizeFooterText;

    @media (max-width:$widthScreenBurger) {
        font-size: .8rem;
    }
}

.types_works {
    display: flex;
    flex-direction: column;
    font-size: $fontSizeFooterText;
    text-decoration: none;
    color: $colorHeader;
    cursor: pointer;

    &:hover {
        color: $colorHover
    }

    @media (max-width:$widthScreenBurger) {
        font-size: .5rem;
    }
}