@import '../../../../../constants/constants.scss';

.container {
    max-width: 280px;
    height: fit-content;
    border-radius: 1rem;
    border: $borderBlock;
    padding: 1rem;
    box-shadow: .2rem .1rem .4rem $colorHover;

    @media (max-width: 1050px) {
        width: 200px;
        font-size: .9rem;
        height: fit-content;
    }

    @media (max-width: 780px) {
        width: 150px;
        padding: .5rem;
    }

    @media (max-width:$widthScreenBurger) {
        //  height: 300px;
        width: 100%;
        font-size: .5rem;
        box-shadow: .2rem .2rem .2rem $colorHover;

    }
        @media (max-width:480px) {
        padding: .3rem;
            // padding: .3rem;
        }
}

.text {
    display: flex;
    width: 100%;
    gap: 1rem;
    margin: 0;
    align-items: center;
    height: 100%;
    // font-weight: bold;

    &::before {
        content: '';
        background-image: url(../../../../../../public/svg/bird.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        min-width: 1.5rem;
        height: 1.5rem;

        @media (max-width: 780px) {
            min-width: 1rem;
            height: 1rem;
        }

        @media (max-width:$widthScreenBurger) {
            min-width: .5rem;
            height: .5rem;

        }

    }
}