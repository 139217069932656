@import '../../../constants/constants.scss';
.header {
    background-color: #000000;
    // height: 100px;
    display: flex;
    min-height: 100px;
    // padding: 0 $paddingLeftRight
    // justify-content: space-evenly;
    // align-items: center;
}
.container_header {
    max-width: $widthContainer;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0;
}
.wrapper_navigate {
    max-width: 1280px;
    width: 100%;
    // padding: 0 -1rem;
}