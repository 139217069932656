@import '../../../../../constants/constants.scss';

.container {
    max-width: 1100px;
    display: flex;
    gap: 2rem;
    margin: 0 auto;
    justify-content: space-evenly;
    padding: 0 1rem;

    @media (max-width:$widthScreenBurger) {
        gap: .5rem;
    }

}

.title {
    font-size: 3rem;
    text-align: center;
    max-width: 50rem;
    margin: 6rem auto;
}

.img {
    width: 500px;
    height: 450px;
    object-fit: cover;
    border:
        20px solid transparent;
    border-image:
        linear-gradient(orange,
            rgb(245, 244, 164),
            rgb(212, 181, 58),
            rgb(227, 196, 73)) 1;

    @media (max-width: 1050px) {
        max-width: 300px;
        height: 400px;
    }

    @media (max-width:$widthScreenBurger) {
        height: 300px;
        width: 50%;
        min-width: 230px;
    }

    @media (max-width:480px) {
        height: 300px;
        width: 35%;
        min-width: 230px;
    }

    @media (max-width:450px) {
        height: 230px;
        width: 35%;
        min-width: 170px;
        border:
            10px solid transparent;
        border-image:
            linear-gradient(orange,
                rgb(245, 244, 164),
                rgb(212, 181, 58),
                rgb(227, 196, 73)) 1;
    }
}

.lite_text {
    font-weight: 400;
}

.wrapper_description {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    align-content: center;

    @media (max-width: 1050px) {
        align-content: center;
    }

    @media (max-width:$widthScreenBurger) {
        gap: .5rem;
    }

}