$colorHeader: whitesmoke;
$paddingLeftRight: 1rem;

$widthContainer: 1280px;
$widthScreenBurger:  650px;

$colorTransparent: rgba(0, 0, 0, 0.5);
$colorButton: linear-gradient(rgba(255, 220, 131, 1), rgba(251, 210, 106, 1), rgba(193, 112, 36, 1));
$colorHover: rgba(255, 220, 131, 1);

$backgroundGold: linear-gradient(rgb(255, 220, 131, 5%), rgba(232, 168, 5, 0.2));


$fontSizeHeader: 1rem;
$fontSizeWelcomeTitle: 37px;
$fontSizeWelcomeTitleBurger: 25px;

$fontSizeWelcomeText: 1rem;
$fontSizeWelcomeTextBurger:.6rem;

$fontSizeTitle: 2rem;
$fontSizeTitleBurger: 1.5rem;

$fontSizeServices: 1.2rem;
$fontSizeServicesBurger:.9rem;

$fontSizeTextBlocks: 1.2rem;
$fontSizeTitleBlocks: 2rem;

$fontSizeFooterText:1rem;

$borderBlock: 1px solid #beb9b9;

$titleMargin: 3rem auto;
$titleMarginTopPages: 5rem auto 3rem auto;

$widthContainersContent: 900px;

$boxShadow: 0rem 1rem 4rem;

$backgroundColorBlock:  rgba(246, 247, 247, 0.74);