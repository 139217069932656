@import '../../../constants/constants.scss';
.footer {
    background-color: #000000;
    display: flex;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;

}
.container_footer {
    max-width: $widthContainer;
    margin: 0 auto;
    padding: 1rem 1rem 1rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    width: 100%;

    @media(max-width: $widthScreenBurger) {
        justify-content: space-between;
    }
}
