@import '../../../constants/constants.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $backgroundColorBlock;
    padding: 2rem;
    box-shadow: $boxShadow;


}

.title {
    text-align: center;
    font-size: $fontSizeTitleBlocks;
    font-style: italic;
    margin: 0;
}

.text {
    font-size: $fontSizeTextBlocks;
    white-space: pre-line;
    text-align: justify;
    text-indent: 10px;
}