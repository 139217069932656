@import '../../../../constants/constants.scss';

.wrapper {
    height: 570px;
    width: 100%;
    background-image: url('../../../../../public/img/Welcom_background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: $widthScreenBurger) {
        height: fit-content;
    }

}