@import '../../../constants/constants.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $backgroundColorBlock;
    padding: 2rem;
    box-shadow: $boxShadow;


}

.head {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;

    @media(max-width:$widthScreenBurger) {
        flex-wrap: wrap;
        gap: 2rem;
    }
}

.head_text {
    white-space: pre-wrap;
    text-align: left;
    width: 50%;

    // text-indent: 10px;
    @media(max-width:$widthScreenBurger) {
        width: 100%;
        text-align: justify;

    }
}

.name,
.requisites_title {
    font-weight: bold;
}

.text {
    white-space: pre-wrap;
    text-align: left;

    @media(max-width:$widthScreenBurger) {
        width: 100%;
        text-align: justify;

    }
}

// .text {
//     white-space: pre-wrap;
//     text-align: left;
//     text-indent: 10px;
// }