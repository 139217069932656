@import '../../../../constants/constants.scss';

.wrapper {
    height: 140px;
    background-color: #000000;
    display: flex;
    justify-content: center;
    margin: 1rem 0;

    @media (max-width:$widthScreenBurger) {
        height: 100px;
    }
}

.blocks {
    margin: 0 auto;
    width: $widthContainer;
    padding: $paddingLeftRight;
    display: flex;
    justify-content: space-around;
    gap: 1rem;
}

.container {}