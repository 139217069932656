@import '../../../../constants/constants.scss';
.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    @media (max-width:800px) {
        display: none;
    }
}

.title,
.address {
    color: whitesmoke;
    font-family: Georgia, 'Times New Roman', Times, serif;
    letter-spacing: 1.5px;
    font-size: .9rem;

}

.title {
    background: -webkit-linear-gradient(rgb(240, 212, 126),
            rgba(255, 220, 131, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.7rem;

}