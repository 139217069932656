@import '../../../../constants/constants.scss';

.wrapper {
    padding: 0 0 3rem 0;
}

.img {
    height: 8rem;
    width: 8rem;

    @media (max-width:$widthScreenBurger) {
        height: 5rem;
        width: 5rem;
    }
}

.container {
    max-width: 750px;
    margin: 0 auto;

}

.wrapper {}

.test {}