@import '../../../../../constants/constants.scss';

.wrapper {
    display: flex;
    gap: 2rem;
    width: 25rem;
    border: 1px solid #beb9b9;
    padding: .5rem;
    border-radius: 1rem;

    @media (max-width:$widthScreenBurger) {
        max-width: 20rem;
        font-size: .6rem;
        padding: 0.2rem;
        gap: 1rem;
    }
}


.container {
    width: 75%;
}

.container_icon {
    width: 18%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width:$widthScreenBurger) {
            width: 15%;
           
        }
}

.title {
    margin: 0 0 .5rem 0;
    font-size: 1.5rem;

    @media (max-width:$widthScreenBurger) {
        font-size: .6rem;
    }
}

.button {}