@import '../../../../../constants/constants.scss';

.title {
    font-size: $fontSizeTitle;
    text-align: center;
    max-width: 50rem;
    margin: $titleMargin;

    @media (max-width:$widthScreenBurger) {
        font-size: $fontSizeTitleBurger;
        padding: 0 1rem;
    }

}

.carousel_item {}

.container {}