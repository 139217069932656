@import '../../../../../../constants/constants.scss';

.wrapper {
    width: 200px;
    height: 60px;
    font-size: $fontSizeWelcomeText;
    background-color: rgba(255, 255, 255, 0.71);
    border: none;
    font-weight: bold;
    transition: 1s;

    @media (max-width:$widthScreenBurger) {
        width: 110px;
        height: 40px;
        font-size: $fontSizeWelcomeTextBurger;
    }

    &:hover {
        background: $colorButton;
    }
}