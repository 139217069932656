@import '../../../../../constants/constants.scss';

.wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    max-width: 15rem;

    @media (max-width:$widthScreenBurger) {
        gap: .5rem;
    }
}

.number {
    font-size: 45px;
    font-weight: bold;
    background: $colorButton;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width:$widthScreenBurger) {
        font-size: 30px;
    }
}

.text {
    color: $colorHeader;
    font-size: 20px;

    @media (max-width:$widthScreenBurger) {
        font-size: 15px;
    }

    @media (max-width:470px) {
        font-size: 11px;
    }
}

.container {}