@import '../../constants/constants.scss';

.wrapper {
    // background: $backgroundGold;
    background-image: url(../../../public/img/background_services.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.container {
    margin: 0 auto 0rem auto;
    padding: 0 0 1rem 0;
    max-width: $widthContainer;
    display: flex;
    flex-direction: column;
    gap: 1rem;


}

.title {
    text-align: center;
}

.container_about {
    margin: 0 auto;
    max-width: $widthContainersContent;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: $fontSizeTextBlocks;
}