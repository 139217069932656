@import '../../../constants/constants.scss';

.title {
    font-size: $fontSizeTitle;
    text-align: center;
    max-width: 34rem;
    margin: $titleMarginTopPages;

    @media (max-width:$widthScreenBurger) {
        font-size: $fontSizeTitleBurger;
        padding: 0 1rem;
        margin: 2rem auto 1rem auto;
    }
}

.lite_text {
    font-weight: 400;
}