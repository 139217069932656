@import '../../../../constants/constants.scss';

.wrapper {
    margin: 0 auto;
    max-width: $widthContainer
}

.container {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    gap: 2rem;

    @media (max-width:$widthScreenBurger) {
            gap: .5rem;
        }
}