@import '../../../../../constants/constants.scss';

.wrapper {
    background-color: $colorTransparent;
    width: 100%;


}

.container {
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    padding: 1rem 1rem;
    transition: .7s;
    transform: translate(-100vw);

}

.container_load {
    transform: translate(0);
}

.title {
    font-family: Georgia, 'Times New Roman', Times, serif;
    letter-spacing: 2px;
    text-align: left;
    font-size: $fontSizeWelcomeTitle;
    font-weight: bold;
    color: $colorHeader;
    margin: 0;

    @media (max-width: $widthScreenBurger) {
        font-size: $fontSizeWelcomeTitleBurger;
    }
}

.highlight {
    background: -webkit-linear-gradient(rgba(227, 165, 72, 1),
            rgba(255, 220, 131, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
}