@import '../../../../constants/constants.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: .5rem 1rem .5rem 0;
    font-size: 1.3rem;

}

.container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.button {
    background: $colorButton;
    padding: 0.6rem;
    display: flex;
    justify-content: center;
    display: flex;

    align-items: center;
    border-radius: 5px;
    text-decoration: none;
    color: #000000;
    text-align: center;
    vertical-align: auto;
    box-shadow: 0rem .5rem 2rem 1rem rgba(227, 165, 72, .3);
    border: none;


    @media (max-width: $widthScreenBurger) {
        display: none;

    }
}