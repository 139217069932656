@import '../../../../../constants/constants.scss';

.wrapper {
    width: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    background-color: #dadada7a;


    @media (max-width:$widthScreenBurger) {
        // padding: 0;
        gap: .5rem;
        width: 150px;
        // height: 110px;
    }
}

.container {
    // background-color: rgb(182 172 172 / 50%);
    font-size: $fontSizeServices;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    // height: 70%;
    // padding: 1rem 1rem 0;
}

.title {
    margin: 0;
    font-size: 1.5rem;

    @media (max-width:$widthScreenBurger) {
        font-size: $fontSizeServicesBurger;
    }
}

.img {
    object-fit: cover;
    width: 100%;
}

.link {
    padding: 1rem 1rem;
    text-align: center;
    font-size: 1.3rem;
    background: $colorButton;
    border: none;
    display: block;
    border-radius: 5px;
    text-decoration: none;
    color: #000000;
    transition: .5s;

    // font-weight: bold;
    // text-transform: uppercase;


    @media (max-width:$widthScreenBurger) {
        font-size: .8rem;
        padding: 0.5rem;

    }

    &:hover {
        
        background: $colorHover;
    }
}