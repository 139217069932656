@import '../../../../../constants/constants.scss';

.title {
    font-size: $fontSizeTitle;
    text-align: center;
    max-width: 35rem;
    margin: $titleMargin;

    @media (max-width:$widthScreenBurger) {
        font-size: $fontSizeTitleBurger;
        padding: 0 1rem;
    }
}

.lite_text {
    font-weight: 400;
}