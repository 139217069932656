.wrapper {
    display: flex;
    margin: 0 0 0 1rem;

}

.logo {
    height: fit-content;
}

.img {
    object-fit: contain;
    width: 10rem;

    @media (max-width: 427px) {
        width: 8rem;

    }
}