@import '../../../../constants/constants.scss';

.wrapper {
    margin: 0 auto 3rem auto;
    max-width: $widthContainer
}

.blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: $fontSizeTextBlocks;
    gap: 1rem;
}

.container {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}